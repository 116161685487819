<template>
	<div class="container" v-loading='loading'>
		<div style="margin-bottom: 15px;background: #fff;padding: 15px;">
			<div slot="header" class="clearfix">
				<span>小程序客服回复配置</span>
			</div>
			<div class="title" style="margin-top: 10px;">
				<div>温馨提示：</div>
				<div>1.回复内容将通过小程序客服发放</div>
				<div>2.用户在小程序内点击加入社群后，或者点击链接到小程序客服的图片导航后，将自动跳转至小程序客服，随后用户发送小程序卡片，小程序客服将自动回复内容</div>
				<div>3.小程序自动回复内容可以设置回复文本和图片</div>
			</div>
			<div v-if="ruleForm.configlist.length<10" style="margin-bottom: 25px;">
				<el-button type='primary' @click="addConfiglist">新增客服回复配置</el-button>
			</div>
		</div>
		<!-- <el-card class="box-card"> -->


		<div style="margin-top: 15px;margin-bottom:120px;">

			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-if="ruleForm.configlist.length">
					<div class="text item" v-for="(item,index) in ruleForm.configlist" :key='index' style="display:flex;border-bottom:1px solid #ddd;padding-bottom: 12px;margin-bottom: 20px;background: #fff;padding: 15px;">
						<div>
							<el-form-item label-width="0px" :prop="'configlist.'+index+'.CommunityGroupCodeTitle'" :rules="item.CommunityGroupCodeTitle?rules.CommunityGroupCodeTitle:rules.nocheck">
								<div style="display: flex;">
									<label style="margin-right: 15px;">小程序卡片标题:</label>
									<div style="position: relative;">
										<el-input type="text" style="width: 500px;" v-model="item.CommunityGroupCodeTitle" placeholder="请输入标题,用于发送小程序卡片时使用"
										 maxlength="30"></el-input>
									</div>
									<div style="cursor: pointer;color:#409EFF;margin-left: 25px;" @click="lookShow">
										查看示例
									</div>
									<div style="color: #F56C6C;cursor: pointer;margin-left: 200px;font-size: 16px;"><span @click='handleDeleteItem(item,index)'>删除该配置</span></div>
								</div>
							</el-form-item>
							
							<el-form-item label-width="0px">
								<div style="display:flex;">
									<label style="margin-right: 15px;">小程序卡片图片:</label>
									<div style="position: relative;">
										<!-- //传递自定义额外形参 -->
										<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="(response, res, file) => handleAvatarSuccess(response, res, file, index)"
										 list-type="picture-card" style="display: inline-block;marin-left:10px;">
											<img  :src="item.AppletCardImgUrl?(imgUrl + item.AppletCardImgUrl):'http://cdn.dkycn.cn/Images/CodeconfigDefault.jpg'" class="upload-avatar" style="width: 148px;height: 148px;"/>
										</el-upload>
										<div style="color: #999;font-size: 14px;">建议尺寸：宽750px，高600px</div>
									</div>
								</div>
								
							</el-form-item>
							<el-form-item label-width="0px">
								<div style="display:flex;">
									<label style="margin-right: 15px;">自动回复文本:</label>
									<div style="position: relative;">
										<el-input type="textarea" style="width: 500px;" placeholder="请输入回复文本内容,最多输入500个字" :rows="6" v-model="item.CommunityGroupCodeText"
										 maxlength="500" show-word-limit resize="none"></el-input>
									</div>
								</div>
							</el-form-item>
							<el-form-item label-width="0px">
								<div style="display: flex;">
									<label style="margin-right: 15px;">自动回复图片:</label>
									<div>
										<div style="color:#999;font-size: 13px;">最多可上传5张，自动回复时，系统将随机发送1张图片</div>
										<div class="filter-item" style="vertical-align: top;">
											<upload-img @transPicture="transPictureFun" :AttachedImgUrlIndex='index' :AttachedImgUrls="item.CommunityGroupCodeImgUrl"
											 :Type="3"></upload-img>
										</div>
									</div>
								</div>
							</el-form-item>
							<el-form-item label-width="0px">
								<div style="display: flex;">
									<label style="margin-right: 15px;">若有锁粉店员，优先回复锁粉店员企业微信二维码图片:</label>
									<div>
										<el-radio-group v-model="item.IsCommunityGroupLockEmployeeFirst">
											<el-radio :label="true">开启</el-radio>
											<el-radio :label="false">关闭</el-radio>
										</el-radio-group>
									</div>
								</div>
								<div style="color: #999;margin-left: 360px;">提示：若开启，则有锁粉店员的客户将不再接收到后台配置的自动回复图片</div>
							</el-form-item>
							<el-form-item label-width="0px">
								<div style="display: flex;">
									<label style="margin-right: 15px;">此条客服回复作为加入社群功能的自动回复:</label>
									<div>
										<el-radio-group v-model="item.IsQyWeixinGroupReply" @change="ChangeGroupReply(item)">
											<el-radio :label="true">开启</el-radio>
											<el-radio :label="false">关闭</el-radio>
										</el-radio-group>
									</div>
								</div>
								<div style="color: #999;margin-left: 360px;">提示：最多只能开启一个自动回复内容作为社群回复内容</div>
							</el-form-item>
						</div>



					</div>
				</el-form>
			</div>

		</div>
		<!-- </el-card> -->
		<el-dialog title="示例图" :visible.sync="lookVisable" width="800px">
			<div style="text-align: center;">
				<img :src="lookPicture" style="width:400px;" />
			</div>
		</el-dialog>
		<div class="footer" style="padding-top: 14px;">
			<el-button style="width:240px;margin-left: 20px;" type="primary" @click="saveRuleform('ruleForm')">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index';
	// import uploadImg from './uploadPicture';
	import {
		communityGroupCodeSettingInit,
		communityGroupCodeSettingSave,
		communityGroupCodeSettingverify
	} from '@/api/TurnTomySelf'
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	export default {
		components: {
			uploadImg,
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var checkCommunityGroupCodeTitle = (rule, value, callback) => {
				// console.log(value, '输入的标题')
				if (value.length > 30) {
					return callback(new Error('请输入1~30字内的标题内容'));
				} else {
					return callback();
				}
			};
			return {
				PageName: '',
				lookVisable: false,
				lookPicture: '',
				Indeximg: 0,
				menulist: [{
					PageName: '首页',
					PagePath: '/pages/index/index'
				}, {
					PageName: '全部商品',
					PagePath: '/pages/allProduct/allProduct'
				}, {
					PageName: '个人中心',
					PagePath: '/pages/personalCenter/mine/mine'
				}, {
					PageName: '购物车',
					PagePath: '/pages/shoppingCart/shoppingCart'
				}, {
					PageName: '积分中心',
					PagePath: '/pages/pointsCenter/pointsCenter'
				}, {
					PageName: '日历签到',
					PagePath: '/pages/userSign/userSign'
				}, {
					PageName: '在线客服',
					PagePath: '/pages/customerService/customerService'
				}, {
					PageName: '订单列表',
					PagePath: '/pages/order/myOrder/myOrder'
				}, {
					PageName: '分销礼包列表',
					PagePath: '/pages/invitation/invitationCard/invitationCard'
				}, {
					PageName: '成为会员',
					PagePath: '/pageA/pages/openSuccess/openSuccess'
				}],
				loading: false,
				imgUrl: config.IMG_BASE,
				fileList1: [],
				limit: 10,
				imgApi: config.UPLOAD_IMG,
				txtVal: 0,
				ruleForm: {
					configlist: []
				},
				rules: {
					nocheck: [{
						validator: checkno,
						trigger: 'blur'
					}],
					CommunityGroupCodeTitle: [{
						required: true,
						trigger: 'blur',
						validator: checkCommunityGroupCodeTitle
					}],
				}
			}
		},
		created() {
			this.getInit()
		},
		methods: {
			handleAvatarSuccess(response, res, file,index) {
				console.log(response, res, file,index,'有传递过来吗9999')
				// const isLt50k = file.size / 1024 < 50;
				// if (!isLt50k) {
				// 	alert('上传图片大小不能超过50kB哦!');
				// 	return false;
				// }
				this.ruleForm.configlist[index].AppletCardImgUrl = response[0];
				this.$forceUpdate();
			},
			//切换开关
			ChangeGroupReply(item) {
				if (item.IsQyWeixinGroupReply) {
					this.PageName = item.PageName
					this.ruleForm.configlist.map(items => {
						if (items.PageName != this.PageName) {
							items.IsQyWeixinGroupReply = false
						}
					})
				}
			},
			// 查看示例
			lookShow() {
				this.lookPicture = 'https://cdn.dkycn.cn/Images/xiaochengxuhuifu.png'
				this.lookVisable = true
			},
			//删除item
			handleDeleteItem(item, index) {
				if (item.Id) {
					this.deleteItem(item.Id, index)
				} else {
					this.ruleForm.configlist.splice(index, 1)
					this.$message({
						type: 'success',
						message: '删除成功',
						showClose: true
					})
				}

			},
			//删除验证
			async deleteItem(Id, index) {
				try {
					let data = {
						Id: Id
					}
					let result = await communityGroupCodeSettingverify(data)
					if (result.IsSuccess) {
						this.ruleForm.configlist.splice(index, 1)
						this.$message({
							type: 'success',
							message: '删除成功',
							showClose: true
						})
					}
				} catch (err) {

				} finally {

				}
			},
			// 数组去重
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr.PagePath) && res.set(arr.PagePath, 1))
			},
			union(setA, setB) {
				var _union = new Set(setA);
				for (var elem of setB) {
					_union.add(elem);
				}
				return _union;
			},
			arrayDate(array1, array2) {
				// var array1 = array1;
				// var array2 = array2;
				var result = [];
				for (var i = 0; i < array2.length; i++) {
					var obj = array2[i];
					var num = obj.PagePath; //staff_id   就是要对比的key
					var isExist = false;
					for (var j = 0; j < array1.length; j++) {
						var aj = array1[j];
						var n = aj.PagePath;
						if (n == num) {
							isExist = true;
							break;
						}
					}
					if (!isExist) {
						result.push(obj);
					}
				}
				return result;
			},
			getRandomArrayElements(arr, count) {
				var shuffled = arr.slice(0),
					i = arr.length,
					min = i - count,
					temp, index;
				while (i-- > min) {
					index = Math.floor((i + 1) * Math.random());
					temp = shuffled[index];
					shuffled[index] = shuffled[i];
					shuffled[i] = temp;
				}
				return shuffled.slice(min);
			},
			//新增客服配置
			addConfiglist() {
				let filetrlist = []
				filetrlist = this.arrayDate(this.ruleForm.configlist, this.menulist)
				let Itemsobj = this.getRandomArrayElements(filetrlist, 1)[0]
				let obj = {
					Id: 0,
					CommunityGroupCodeTitle: '',
					CommunityGroupCodeText: '',
					CommunityGroupCodeImgUrl: [],
					IsCommunityGroupLockEmployeeFirst: false,
					PageName: Itemsobj.PageName,
					PagePath: Itemsobj.PagePath,
					MenuType: 2,
					IsQyWeixinGroupReply: false,
					AppletCardImgUrl :''
				}
				this.ruleForm.configlist.unshift(obj)
				this.$forceUpdate()
			},
			async getInit() {
				try {
					this.loading = true
					let result = await communityGroupCodeSettingInit()
					if (result.IsSuccess) {
						let that = this
						this.ruleForm.configlist = JSON.parse(JSON.stringify(result.Result))
						this.ruleForm.configlist.forEach((item, index) => {
							that.$set(item, 'CommunityGroupCodeImgUrl', result.Result[index].CommunityGroupCodeImgUrl)
						})
						this.$forceUpdate()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			async saveRuleform() {
				try {
					let flag = true;
					this.ruleForm.configlist.map(item => {
						if (!item.CommunityGroupCodeText && item.CommunityGroupCodeImgUrl.length == 0) {
							flag = false
						}
					})
					if (!flag) {
						this.$message({
							type: 'error',
							message: '自动回复文本或回复图片，至少配置1项',
							showClose: true
						})
						return
					}
					this.ruleForm.configlist.map(item => {
						if (item.CommunityGroupCodeImgUrl.length > 5) {
							item.CommunityGroupCodeImgUrl.splice(5)
						}
					})
					this.$forceUpdate()
					// let length = this.ruleForm.configlist.length
					// this.menulist = this.menulist
					//    let newulist = this.menulist.slice(0,length)

					// let reverselist = newulist.reverse()

					// console.log(reverselist,'翻转呦')
					// this.ruleForm.configlist.forEach((items,indexs) =>{
					// 	items.PageName = reverselist[indexs].PageName
					// 	items.PagePath = reverselist[indexs].PagePath
					// })

					this.ruleForm.configlist.forEach((item, index) => {
						item.SortIndex = index
					})
					this.loading = true
					let data = {
						CustomerServiceReplyContentList: this.ruleForm.configlist
					}
					let result = await communityGroupCodeSettingSave(data)
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '操作成功',
							showClose: true
						})
						this.getInit()
					}

				} catch (err) {
					this.loading = false
				} finally {
					this.loading = false
				}

			},
			// 批量上传的图片
			transPictureFun(datas, index) {
				this.ruleForm.configlist[index].CommunityGroupCodeImgUrl = datas
				this.$forceUpdate()
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		.footer {
			background-color: #fff;
			border-top: 1px solid #ebeef5;
			width: 100%;
			height: 80px;
			text-align: center;
			line-height: 80px;
			position: fixed;
			bottom: 0px;
			z-index: 10;
		}

		.title {
			color: #999;
			font-size: 13px;

			div {
				margin-bottom: 10px;
			}
		}
	}
</style>
